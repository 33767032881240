




















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import ReportModule from './ReportModule.vue'
import { round } from 'lodash'

@Component({
  components: {
    ReportModule,
  },
})
export default class YearlyReport extends Vue {
  @Prop() public readonly data!: any
  @Prop() public readonly type!: any

  private Vue = Vue
  private round = round

  private get gData(): any {
    return [
      {
        name: this.data.schoolYear + ' GPA',
        GPA: round(this.data.annualGPA, 2),
      },
      {
        name: 'Cumulative GPA',
        GPA: round(this.data.cumulativeGPA, 2),
      },
    ]
  }

  private get SData(): Array<any> {
    return this.data?.gradeStandards || []
  }

  private get tData(): any {
    const data = this.data.reportItems?.map(item => {
      const semester = {}
      Object.keys(item.semesterGrades).forEach(key => {
        const score =
          item.semesterGrades[key].score || item.semesterGrades[key].score === 0
            ? round(item.semesterGrades[key].score, 1)
            : '-'
        this.$set(
          semester,
          's' + key,
          this.type === 'middle' ? score : item.semesterGrades[key].grade
        )
        this.$set(semester, 'm' + key, item.semesterGrades[key].mastery)
      })
      return {
        ...item,
        ...semester,
        credits: round(item.credits, 2),
      }
    })
    return data || []
  }

  private get mColumns(): any {
    let dynamic =
      this.data.semesters
        ?.map(item => [
          {
            dataIndex: 's' + item.semesterId,
            title: item.semesterType,
            scopedSlots: { customRender: 'semester' },
          },
          {
            dataIndex: 'm' + item.semesterId,
            title: item.semesterType + ' Mastery',
          },
        ])
        .flat() || []
    return [
      {
        dataIndex: 'year',
        title: 'Year',
      },
      {
        dataIndex: 'subjectName',
        title: 'Course',
      },
      {
        dataIndex: 'teachers',
        title: 'Current Teacher',
      },
      ...dynamic,
      {
        dataIndex: 'finalScore',
        title: 'Overall Grade',
        scopedSlots: { customRender: 'grade' },
      },
      {
        dataIndex: 'finalMastery',
        title: 'Overall Mastery',
        scopedSlots: { customRender: 'mastery' },
      },
      {
        dataIndex: 'absentNum',
        title: 'Total Abs',
      },
    ]
  }

  private get hColumns(): any {
    let dynamic =
      this.data.semesters?.map(item => ({
        dataIndex: 's' + item.semesterId,
        title: item.semesterType,
        scopedSlots: { customRender: 'semester' },
      })) || []
    return [
      {
        dataIndex: 'year',
        title: 'Year',
      },
      {
        dataIndex: 'courseName',
        title: 'Course',
      },
      {
        dataIndex: 'courseType',
        title: 'Des.',
      },
      {
        dataIndex: 'teachers',
        title: 'Teacher',
      },
      ...dynamic,
      {
        dataIndex: 'meGrades',
        title: 'ME',
        scopedSlots: { customRender: 'grade' },
      },
      {
        dataIndex: 'feGrades',
        title: 'FE',
        scopedSlots: { customRender: 'grade' },
      },
      {
        dataIndex: 'letter',
        title: 'Overall',
        scopedSlots: { customRender: 'grade' },
      },
      {
        dataIndex: 'absentNum',
        title: 'Abs.',
      },
      {
        dataIndex: 'credits',
        title: 'Credits',
      },
    ]
  }

  private get SColumns(): Array<any> {
    return [
      this.type === 'middle'
        ? {
            key: 'grade',
            title: '评分等级\nGrade',
            width: 130,
            scopedSlots: { customRender: 'grade' },
          }
        : undefined,
      {
        key: 'levelOfMastery',
        title: '精熟程度\nLevel of Mastery',
        scopedSlots: { customRender: 'LOM' },
        width: 160,
      },
      {
        dataIndex: 'description',
        key: 'DOP',
        title: '表现描述\nDescription of Performance',
      },
    ].filter(item => item)
  }

  private get gColumns(): any {
    return [
      {
        dataIndex: 'name',
        title: 'Name',
        width: 200,
      },
      {
        dataIndex: 'GPA',
        title: 'GPA',
      },
    ]
  }

  private get filterAdvisorComment(): any {
    return this.data.advisorComments?.filter(item => item.comment) || []
  }
}
